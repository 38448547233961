<template>
  <div>
    <!-- ==== DIALOG DETAIL ==== -->
    <v-dialog v-model="dialog.detail" persistent width="450" scrollable :fullscreen="$vuetify.breakpoint.name === 'xs' ? true : false">
      <v-card :class="$vuetify.breakpoint.name === 'xs' ? '' : 'border-radius box-shadow'">
        <v-card-title
          class="body-2 white--text"
          style="background-color: #d31145;"
        >
          Datail Stok
        </v-card-title>
        <v-card-text class="pt-5">
          <div>
            <v-list-item-title class="body-2 grey--text text--darken-2">
              <p class="caption grey--text text-lighten-4 mb-0">
                Nama Produk
              </p>
              {{ detail.name }}
            </v-list-item-title>
            <v-list-item-title class="body-2 grey--text text--darken-2 mt-2">
              <p class="caption grey--text text-lighten-4 mb-0">
                Kode Produk
              </p>
              {{ detail.product_code }}
            </v-list-item-title>
            <v-list-item-title class="body-2 grey--text text--darken-2 mt-2">
              <p class="caption grey--text text-lighten-4 mb-0">
                Kategori Produk
              </p>
              {{ detail.product_category_name }}
            </v-list-item-title>
          </div>
          <v-card-actions class="pt-2 px-0">
            <div style="width: 100%">
              <v-card-title
                class="text-third px-0 py-0"
                style="font-size: 11px;"
              >
                Stok Awal
              </v-card-title>
              <v-card-text class="headline text-second">
                {{ detail.stock_awal }}
              </v-card-text>
            </div>
            <v-spacer />
            <v-divider vertical :class="$vuetify.breakpoint.name === 'xs' ? 'my-4 mx-3' : 'my-4 mx-5'" />
            <v-spacer />
            <div style="width: 100%;">
              <v-card-title
                class="text-third px-0 py-0"
                style="font-size: 11px;"
              >
                Stock Minimum
              </v-card-title>
              <v-card-text class="headline text-second">
                {{ detail.stock_minimum }}
              </v-card-text>
            </div>
            <v-spacer />
            <v-divider vertical :class="$vuetify.breakpoint.name === 'xs' ? 'my-4 mx-3' : 'my-4 mx-5'" />
            <v-spacer />
            <div style="width: 100%;">
              <v-card-title
                class="text-third px-0 py-0"
                style="font-size: 11px;"
              >
                Stok Sekarang
              </v-card-title>
              <v-card-text class="headline text-second">
                {{ detail.stock_balance }}
              </v-card-text>
            </div>
          </v-card-actions>
          <v-card-actions class="pt-1 px-0">
            <div style="width: 100%">
              <v-card-title
                class="text-third px-0 py-0"
                style="font-size: 11px;"
              >
                Stok Pembelian
              </v-card-title>
              <v-card-text class="headline text-second">
                {{ detail.stock_po }}
              </v-card-text>
            </div>
            <v-spacer />
            <v-divider vertical :class="$vuetify.breakpoint.name === 'xs' ? 'my-4 mx-3' : 'my-4 mx-5'" />
            <v-spacer />
            <div style="width: 100%;">
              <v-card-title
                class="text-third px-0 py-0"
                style="font-size: 11px;"
              >
                Stok Penjualan
              </v-card-title>
              <v-card-text class="headline text-second">
                {{ detail.stock_penjualan }}
              </v-card-text>
            </div>
            <v-spacer />
            <v-divider vertical :class="$vuetify.breakpoint.name === 'xs' ? 'my-4 mx-3' : 'my-4 mx-5'" />
            <v-spacer />
            <div style="width: 100%;">
              <v-card-title
                class="text-third px-0 py-0"
                style="font-size: 11px;"
              >
                Stok Penyesuaian
              </v-card-title>
              <v-card-text class="headline text-second">
                {{ detail.stock_adjustment }}
              </v-card-text>
            </div>
          </v-card-actions>
          <v-card-actions class="pt-1 px-0">
            <div style="width: 100%">
              <v-card-title
                class="text-third px-0 py-0"
                style="font-size: 11px;"
              >
                Stok Konversi
              </v-card-title>
              <v-card-text class="headline text-second">
                {{ detail.stock_converter }}
              </v-card-text>
            </div>
            <v-spacer />
            <v-divider vertical :class="$vuetify.breakpoint.name === 'xs' ? 'my-4 mx-3' : 'my-4 mx-5'" />
            <v-spacer />
            <div style="width: 100%;">
              <v-card-title
                class="text-third px-0 py-0"
                style="font-size: 11px;"
              >
                Stok Transfer
              </v-card-title>
              <v-card-text class="headline text-second">
                {{ detail.stock_transfer }}
              </v-card-text>
            </div>
            <v-spacer />
            <v-divider vertical :class="$vuetify.breakpoint.name === 'xs' ? 'my-4 mx-3' : 'my-4 mx-5'" />
            <v-spacer />
            <div style="width: 100%;"></div>
          </v-card-actions>
        </v-card-text>
        <v-card-actions class="px-5 py-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.detail = false"
          >
            <v-icon color="#d31145" small class="mr-1"
              >mdi-close-circle</v-icon
            >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DATA STOCK ==== -->
    <v-row class="justify-space-between">
      <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 6 : 6">
        <div class="text--secondary mb-2" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 font-weight-bold' : 'title'">
          Data Rekap Stok
        </div>
        <div class="d-flex">
          <Export
            class="mb-3 mr-2"
            :file_type="'xls'"
            :btnText="'Export Excel'"
            :btnIcon="'mdi-microsoft-excel'"
            :btnColor="'teal'"
            :url="`franchise/report/recap/stock/export_excel?recap_job_id=${this.$route.params.id}`"
            :title="'Data Stok'"
            ref="base-export"
            v-on:onClickExport="responseExport"
          />
          <Export
            class="mb-3 mr-2"
            :disabled="$store.state.loading"
            :file_type="'pdf'"
            :btnText="'Export PDF'"
            :btnIcon="'mdi-file-pdf-box'"
            :btnColor="'#f40f02'"
            :url="`franchise/report/recap/stock/export_pdf?recap_job_id=${this.$route.params.id}`"
            :title="'Data Stok'"
            ref="base-export-pdf"
            v-on:onClickExport="responseExport"
          />
          <!-- <v-btn
            depressed
            :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
            :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
            :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
            elevation="0"
            :disabled="process.run"
            color="#aaa9ad"
            class="white--text text-capitalize"
            @click="$refs['base-table'].isFilterVisible = true"
          >
            <span v-if="$vuetify.breakpoint.name !== 'xs'">Filter</span>
            <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-filter-variant</v-icon>
          </v-btn> -->
        </div>

      </v-col>

      <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 6 : 4">
        <v-text-field
          outlined
          dense
          :hide-details="true"
          v-model="searching"
          placeholder="Cari Data . . ."
          clearable
          color="#d31145"
          @click:clear="
            searching = '';
            fetch();
            showPage = false;
          "
          v-on:keyup.enter="
            fetch();
            showPage = false;
          "
          prepend-inner-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <div v-if="$vuetify.breakpoint.name === 'xs'">
      <strong>Mohon Perhatian !</strong> Baris yang berwarna
      <v-chip label color="#FFCDD2">Merah</v-chip> merupakan stok yang
      kurang dari atau sama dengan stok minimum .
    </div>
    <v-divider style="display: none;" class="mt-4" v-if="$vuetify.breakpoint.name !== 'xs'"></v-divider>

    <!-- ==== LIST DATA MONITORING STOK ==== -->
    <div class="my-5" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'min-height: calc(100vh - 485px)'">
      <v-skeleton-loader
        :loading="process.run"
        transition="scale-transition"
        :tile="false"
        type="table-tbody"
      >
        <!-- ====BASE TABLE==== -->
        <base-table
          :customHeight="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'calc(100vh - 460px)'"
          v-show="displayBaseTable"
          :isExternalFilterButton="true"
          :isFilterOnHeader="true"
          ref="base-table"
          :filterHeader="filterHeader_stock"
          itemKey="id"
          :single-select="singleSelect"
          :headers="headers"
          :retrieveDataURL="`franchise/report/recap/stock?recap_job_id=${this.$route.params.id}`"
          v-on:onRetrieveDataEnd="responseDataEnd"
        >
        <!-- ?filter[5][type]=string&filter[5][field]=product_is_have_variant&filter[5][comparison]===&filter[5][value]=Tidak -->
          <template v-slot:[`item.aksi_table`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  min-width="30"
                  class="text-capitalize orange--text text--darken-1 pa-0 mr-1"
                  @click="toDetail(item)"
                >
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
              </template>
              <span>Detail Stok</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.product_name`]="{ item }">
            {{ item.product_name }}
          </template>
          <template v-slot:[`item.code`]="{ item }">
            {{ item.code }}
          </template>
          <template v-slot:[`item.product_category_name`]="{ item }">
            {{ item.product_category_name }}
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ item.type == 'ingredient' ? 'Bahan baku' : item.type == 'package' ? 'Paket' : 'Produk' }}
          </template>
          <template v-slot:[`item.is_active_bool`]="{ item }">
            <div
              :class="
                item.is_active_bool
                  ? 'caption font-weight-bold text-center teal--text'
                  : 'caption font-weight-bold text-center red--text text--darken-2'
              "
            >
              {{ item.is_active_bool ? "Aktif" : "Tidak aktif" }}
            </div>
          </template>
          <template v-slot:[`item.stock_balance`]="{ item }">
            {{ item.stock_balance }}
          </template>

          <template v-slot:filterTitle>
            Filter Data Stok
          </template>
        </base-table>
      </v-skeleton-loader>
      <!-- ====END BASE TABLE ==== -->

      <!-- ==== COMPONENT DATA KOSONG ==== -->
      <Empty v-model="contentEmpty" v-show="emptyData" />
    </div>
    <!-- <v-divider v-show="pagination.data.total_page > 0" class="mt-0"></v-divider> -->
  </div>
</template>
<script>
import { get, post, put, destroy } from "@/service/Axios";
import { TokenService } from "@/service/Storage.Service";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import Export from "@/components/Export";
export default {
  data() {
    return {
      /**
       * DATA COMPONENT BASETABLE
       */
      displayBaseTable: false,
      emptyData: false,
      arr_categoryProduct: [],
      singleSelect: true,
      headers: [
        // {
        //   text: "Aksi",
        //   sortable: false,
        //   width: "10",
        //   value: "aksi_table",
        //   align: "center",
        // },
        {
          text: "Kode Produk",
          sortable: true,
          width: "100",
          value: "code",
          align: "center",
        },
        {
          text: "Nama Produk",
          sortable: true,
          width: "100",
          value: "name",
          align: "start",
        },
        {
          text: "Kategori Produk",
          width: "100",
          align: "start",
          sortable: true,
          value: "category_name",
        },
        {
          text: "Tipe Produk",
          width: "100",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Status",
          width: "100",
          sortable: true,
          value: "is_active_bool",
          align: "center",
        },
        {
          text: "Stok Minimum",
          width: "100",
          sortable: true,
          value: "minimum",
          align: "end",
        },
        {
          text: "Stok Sekarang",
          width: "100",
          sortable: true,
          value: "balance",
          align: "end",
        },
      ],
      dataPagination: {
        current: 0,
        limit: 0,
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      },
      /**
       * END DATA COMPONENT BASETABLE
       */

      color: "",
      response: "",
      searching: "",
      contentEmpty: "",
      detail: {},
      pagination: {
        data: {},
        page: "",
      },
      limit: 10,
      page: 1,
      avatar: null,
      showPage: false,
      showPreviewImage: null,
      selected: {
        pagination: 0,
        menu: 0,
      },
      form: {
        user_id: "",
      },
      process: {
        form: false,
        run: false,
      },
      dialog: {
        delete: false,
        ImagePreview: false,
        snackbar: false,
        detail: false,
        form: false,
      },
      list: [],
    };
  },
  components: {
    Empty,
    Pagination,
    Export,
  },
  watch: {
    searching: _.debounce(function(newVal) {
      this.searching = newVal;
      this.pagination.page = newVal === null ? "" : 1;
      // this.fetch();
      this.$refs["base-table"].searchVal = newVal;
      this.$refs["base-table"].retrieveData();
    }, 500),
    // "selected.pagination": function(val) {
    //   this.fetch(val + 1);
    // },
    "selected.menu": function(val) {
      this.selected.menu = val === undefined ? 0 : this.selected.menu;

      // this.fetch();
    },
  },
  created() {
    // this.fetch();
    // this.getCategoryProduct();
  },
  computed: {
    user() {
      return JSON.parse(TokenService.getUser());
    },
    selected_store_id () {
      return JSON.parse(localStorage.getItem('selected_store_id'));
    },
    /**
     * FILTER HEADER STOCK
     */
    filterHeader_stock() {
      return [
        // {
        //   type: "opsi",
        //   column: "",
        //   valueDefault: "",
        //   label: "",
        // },

        {
          type: "text",
          column: "name",
          valueDefault: "",
          label: "Berdasarkan Nama Produk",
        },
        {
          type: "autocomplete",
          column: "category_name",
          valueDefault: "",
          items: this.arr_categoryProduct,
          label: "Berdasarkan Kategori",
        },
        {
          type: "opsi",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "select",
          column: "is_active_bool",
          valueDefault: "",
          label: "Pilih Status",
          items: ["Aktif", "Tidak Aktif"],
        },
        {
          type: "opsi",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "text",
          column: "stock_balance",
          valueDefault: "",
          label: "Berdasarkan Stok Sekarang",
        },
      ];
    },
    /**
     * END FILTER HEADER STOCK
     */
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    row_classes(item) {
      // console.log(item)
        if (item.color != '#FFFFFF') {
          return "color_table";
        } 
    },
    /**
     * HANDLE EXPORT
     */
    responseExport() {
      //send params from base table to base export
      this.$refs["base-export"].params = this.$refs["base-table"].params;

      // KALAU MAU PAKE SEARCH
      this.$refs["base-export"].params.search = this.$refs[
        "base-table"
      ].searchVal;

      this.$refs["base-export-pdf"].params = this.$refs["base-table"].params;
    },
    /**
     * END HANDLE EXPORT
     */

    /**
     * HANDLE RESPONSE FROM COMPONENT DATATABLE
     */
    responseDataEnd(res) {
      this.loading = false;
      this.process.run = false;
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data belum tersedia atau data yang Anda cari tidak tersedia";
    },
    /**
     * HANDLE RESPONSE FROM COMPONENT DATATABLE
     */
    async toDetail(item) {
      this.detail = item;
      this.dialog.detail = true;
    },

    // changePage(event) {
    //   if (event === undefined) {
    //     this.selected.pagination = 0;
    //   }
    // },
  },
};
</script>

<style>
.line:hover {
  border-left: 6px solid #d31145;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  transition: 0.3s;
}
.background-cols {
  background-color: #ffca8e61;
  border: 3px solid #ffc58461;
  border-radius: 10px;
}
.br-20 {
  border-radius: 20px;
}
.pagination {
  position: fixed;
  bottom: 65px;
}
.color_table {
  background-color: #FFCDD2 !important;
}
</style>
